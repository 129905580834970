import React, { useState } from "react"

import axios from "axios"
import { Formik } from "formik"

import InfoForm from "./InfoForm"
import "./InfoContact.css"

const EMAILER_API = "https://emailer.technocube.co"
//const EMAILER_API = "http://localhost:4444"
const mobileRegex = /^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[7-9]\d{9})?$/
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

export default function InfoContact() {
  const [submitted, setSubmitted] = useState(false)
  const submitQuery = (data, resolve, reject) => {
    axios({
      method: "POST",
      baseURL: EMAILER_API,
      url: "/api/savanna-web/email",
      data,
    })
      .then(res => {
        console.log("response", res)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  }
  return (
    <div className="infoContactContainer">
      <div className="infoContact">
        <div className="infoContactHeading">
          <p>We Make Hiring Easy. It's that simple.</p>
          <p>
            Just give us a few details in the form below and one of our staffing
            professionals will contact you.
          </p>
        </div>
        <div className="infoContactDetails">
          <p id="info">Contact Information</p>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              company: "",
              jobTitle: "",
            }}
            validate={values => {
              const errors = {}

              if (!values.firstName) {
                errors.firstName = "*First name is required."
              }

              if (!values.phone) {
                errors.phone = "*Phone number is required."
              }

              if (values.phone && !mobileRegex.test(values.phone)) {
                errors.phone = "*Phone number is not valid."
              }

              if (!values.email) {
                errors.email = "*Email address is required."
              }

              if (values.email && !emailRegex.test(values.email)) {
                errors.email = "*Email address is invalid."
              }

              return errors
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              console.log("values", values)
              setSubmitting(true)
              submitQuery(
                values,
                () => {
                  console.log("resolve")
                  setSubmitting(false)
                  setSubmitted(true)
                  resetForm()
                  setTimeout(() => {
                    setSubmitted(false)
                  }, 2000)
                },
                e => {
                  setSubmitting(false)
                  console.log("reject")
                }
              )
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <InfoForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                submitted={submitted}
              />
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
