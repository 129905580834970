import React from "react"

import Title from "../components/Title"
import Footer from "../components/Footer"

import InfoContact from "../components/ContactPageComponents/InfoContact/InfoContact"
import Hiring from "../components/Hiring/Hiring.js"

export default function Contact() {
  return (
    <>
      <Title title="GET IN TOUCH" subTitle="Contact Us" />
      <InfoContact />
      <Hiring />
      <Footer />
    </>
  )
}
