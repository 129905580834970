import React from "react"

const InfoForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  submitted,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="input-wrapper">
        <div className="input-group">
          <input
            placeholder="First name"
            name="firstName"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
          />
          {errors.firstName && (
            <span className="text-danger">{errors.firstName}</span>
          )}
        </div>
        <div className="input-group">
          <input
            placeholder="Last name"
            name="lastName"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
          />
        </div>
      </div>
      <div className="input-wrapper">
        <div className="input-group">
          <input
            placeholder="Business Phone"
            name="phone"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
          />
          {errors.phone && <span className="text-danger">{errors.phone}</span>}
        </div>
        <div className="input-group">
          <input
            placeholder="Work Email"
            name="email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && <span className="text-danger">{errors.email}</span>}
        </div>
      </div>
      <div className="input-wrapper">
        <div className="input-group">
          <input
            placeholder="Company"
            name="company"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.company}
          />
        </div>
        <div className="input-group">
          <input
            placeholder="Job Title"
            name="jobTitle"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.jobTitle}
          />
        </div>
      </div>
      {submitted && (
        <p>
          Thank you for your request staff. Our representative connect with you
          soon.
        </p>
      )}
      <button type="submit">
        {isSubmitting ? (
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          "Request Staff"
        )}
      </button>
      <br />
      <p>
        Or email to us at{" "}
        <a href="mailto:hello@savannahr.com">hello@savannahr.com</a>
      </p>
    </form>
  )
}

export default InfoForm
